//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(["user"]),
    formattedCards() {
      return this.blok.offer_cards.map(card => {
        const shouldOpenInNewTab = card.link[0].link.target === "_blank";
        const linkElement = card.link[0].link.linktype === "story" && !shouldOpenInNewTab ? 'nuxt-link' : 'a';
        return {
          ...card,
          linkElement,
          shouldOpenInNewTab
        }
      });
    }
  },
  methods: {
    formatUrl(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
      }
      return url;
    }
  }
};
